import React, { useState, useEffect } from "react";
import { Colors, Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import {
  EVENT_CLEAR_PATIENT,
  EVENT_RELOAD_PRESCRIPTIONS,
  EVENT_SHOW_MODAL_ACTION,
  EVENT_SHOW_MODAL_INFO,
  EVENT_VALIDATE_PATIENT,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import {
  validateEmptyArrays,
  validateEmptyFields,
} from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { showModalInfo, showModalWithInput, showModalWithInputCuit } from "../../../modules/ViewHelpers";
import { api, dateTimeService, helpers, appHelpers } from "../../../import";
import { useTheme } from "../../../configs/Theme";
import CoachMarksService from "../../../modules/coachmarks";
import { ConsultsType } from "../../../configs/Const";
import {
  INSURANCE_PERMISSION,
  MEDICINES_PERMISSION,
  PRESCRIPTION_ERROR_INVALID_CREDENTIAL,
  PRESCRIPTION_ERROR_INVALID_TAX_ID,
  PRESCRIPTION_MODE_NO_MEDICAL_INSURANCE,
  PRESCRIPTION_REQUEST_STATUS_ACCEPTED,
  USER_TYPE_PATIENT,
} from "../../../models/constants";
import LocalAuthService from "../../../modules/LocalAuthService";
import {
  setInStorage,
  AsyncStorageEnum,
  getFromStorage,
  removeFromStorage,
} from "../../../utils/storage";
import SearchMedicines from "../../SearchMedicines";
import DataUpdater from "../../DataUpdater";
import validateCuitByCountry from "../../../utils/validation/cuit";


const useAddPrescriptionPage = (props: any) => {
  const history: any = useHistory();
  const params: any = history.location.state;
  let goBackAlreadyDismissed = false;

  const coachMarksService = new CoachMarksService();
  const localAuthService = new LocalAuthService();

  const [prescriptionId, setPrescriptionId] = useState(
    params ? params.prescriptionId : null
  );

  const [prescriptionRequestId, setPrescriptionRequestId] = useState(
    params ? params.prescriptionRequestId : null
  );

  const [patientId, setPatientId] = useState(
    params ? params.selectPatientId : null
  );

  const [errors, setErrors] = useState([]);

  const [prescriptionError, setPrescriptionError] = useState(false);

  const [progress, setProgress] = useState(0);

  const showProgress = (total: any, current: any) => {
    setProgress(((current * 100) / total / 100).toFixed(1));
  };

  const [prescriptionData, setPrescriptionData] = useState(null);
  const [overlay, setOverlay] = useState<any>(false);
  const [congrats, setCongrats] = useState(false);
  const [prescriptionProfiles, setPrescriptionProfiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alreadySent, setAlreadySent] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [prescriptionProfileId, setPrescriptionProfileId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [diagnostic, setDiagnostic] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.diagnostic
      : ""
  );
  const [diagnosticCod, setDiagnosticCod] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.diagnosticCod
      : ""
  );
  const [indications, setIndications] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.indications
      : ""
  );
  const [comments, setComments] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.comments
      : ""
  );
  const [extendedTreatment, setExtendedTreatment] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.extended_treatment
      : false
  );
  const [hiv, setHiv] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.is_hiv
      : false
  );
  const [coachmark, setCoachmark] = useState(false);
  const [medicalInsuranceSelected, setMedicalInsuranceSelected] =
    useState(false);
  const [medicalInsuranceIdSelected, setMedicalInsuranceIdSelected] =
    useState(null);
  const [medicines, setMedicines] = useState(
    params && params.prevPrescriptionData
      ? params.prevPrescriptionData.medicines
      : []
  );
  const { theme } = useTheme();
  const initialDate = new Date();
  const minDate = new Date();
  // minDate.setMonth(minDate.getMonth() - 1);
  const [initialSource, setInitialSource] = useState(params && params.initialSource ? params.initialSource : null);
  const [dates, setDates] = useState([initialDate]);
  const [medicalInsurances, setMedicalInsurances] = useState<any>([]);
  const [overflowFlag, setOverflowFlag] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [insurancePermissionFlag, setInsurancePermissionFlag] = useState(false);
  const [enableGeneric, setenableGeneric] = useState(true);
  const [clinicId, setClinicId] = useState(null);

  const [
    selectPrescriptionProfileEnabled,
    setSelectPrescriptionProfileEnabled,
  ] = useState(false);

  const setPrescriptionProfile = async (profileId: any) => {
    if (profileId) {
      setPrescriptionProfileId(profileId);
      setInStorage(AsyncStorageEnum.PRESCRIPTION_PROFILE_ID, `${profileId}`);
    } else {
      let getPrescriptionProfile = await getFromStorage(
        AsyncStorageEnum.PRESCRIPTION_PROFILE_ID
      );
      if (getPrescriptionProfile !== null) {
        setPrescriptionProfileId(JSON.parse(getPrescriptionProfile));
      }
    }
  };

  const loadPrescriptionProfiles = () => {
    api
      .getMyPrescriptionProfiles()
      .then((res: any) => {
        setPrescriptionProfiles(res.data);
        if (res && res.data && res.data.length) {
          if (!prescriptionId && !prescriptionRequestId) {
            let getPrescriptionProfile = getFromStorage(
              AsyncStorageEnum.PRESCRIPTION_PROFILE_ID
            );
            if (getPrescriptionProfile !== null) {
              setPrescriptionProfile(JSON.parse(getPrescriptionProfile));
            } else {
              setPrescriptionProfile(res.data[0].id);
            }
          }
        } else {
          showModalInfo(
            locales_es.errorModal.title,
            "No hay un perfil de receta disponible. Debe crear al menos uno para generar recetas"
          );
        }
        setData(res.data);
        getDefaultCheckInsurance(res.data);
      })
      .catch((err: any) => {
        setData();
        helpers.processError(err);
      });
  };

  const setData = (profilesData = null) => {
    if (prescriptionId) {
      api
        .getPrescription(prescriptionId)
        .then((res: any) => {
          const p = res.data;
          setPrescriptionProfile(p.prescription_profile_id);
          setMedicines(
            p.medicines.map((medicines: any) => {
              return {
                ...medicines,
                observations: medicines.comment ?? "",
              };
            })
          );
          setDiagnostic(p.diagnostic);
          setDiagnosticCod(p.diagnostic_cod);
          setIndications(p.indications);
          setComments(p.comments);
          setExtendedTreatment(Boolean(p.extended_treatment));
          setHiv(Boolean(p.is_hiv));
          setDates([new Date()]);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    }

    if (prescriptionRequestId) {
      api
        .getPrescriptionRequest(prescriptionRequestId)
        .then((res: any) => {
          const p = res.data;
          // setPatientId(p.patient_id); // its configured passing patiendId as param
          setPrescriptionProfile(p.prescription_profile_id);

          if (localStorage.getItem("tieneCobertura") === "1") {
            const updatedMedicines = p.medicines.map((medicine: any) => {
              return {
                ...medicine,
                no_replacement: true,
                observations: medicine.comment ?? "",
              };
            });
            setMedicines(updatedMedicines);
          } else {
            setMedicines(
              p.medicines.map((medicines: any) => {
                return {
                  ...medicines,
                  observations: medicines.comment ?? "",
                };
              })
            );
          }

          setDiagnostic(p.diagnostic);
          setDiagnosticCod(p.diagnosticCod);
          setIndications(p.indications);
          // setComments(p.comments); // it was commented because this field is used in requests for patients leaving a comment
          setExtendedTreatment(Boolean(p.extended_treatment));
          setHiv(Boolean(p.is_hiv));
          setMedicalInsuranceIdSelected(p.medical_insurance_id);
          setSelectPrescriptionProfileEnabled(!p.clinic_id ? true : false);
          if (p.clinic_id === null && profilesData !== null) {
            const profiles = profilesData.filter(
              (profile) => profile.clinic_id == null
            );
            setPrescriptionProfiles(profiles);
          }
          setDates([new Date()]);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    } else {
      setSelectPrescriptionProfileEnabled(true);
    }
  };

  const getDefaultCheckInsurance = (_userData: any) => {
    let patient: any = _userData;

    if (patient) {
      for (let i = 0; i < patient.patients_medical_insurances.length; i++) {
        const p = patient.patients_medical_insurances[i];
        if (p.medical_insurance_selected == 1) {
          let idFinanciador = p.medical_insurance.idFinanciador
            ? p.medical_insurance.idFinanciador
            : 0;
          let tieneCobertura = p.medical_insurance.tieneCobertura
            ? p.medical_insurance.tieneCobertura
            : null;

          //Vademecun Especial
          if (idFinanciador > 0 && tieneCobertura == 1) {
            localStorage.setItem("tieneCobertura", "1");
          }
          break;
        }
      }
    }
  };

  const setProfileByPatientId = (patientId: any, clinicId: any, prescriptionProfiles = null) => {
    if (patientId && prescriptionProfiles != null) {
      prescriptionProfiles.map(profile => {
        if (profile.clinic_id !== null && profile.clinic_id === clinicId) {
          setPrescriptionProfile(profile.id);
        }
      });
    }
  }

  const loadPatientData = (patientId: any) => {
    setPatientId(patientId);
  };

  const validatePatient = () => {
    if (patientId) {
      api
        .getPatient(patientId)
        .then((res: any) => {
          setUserData(res.data);
          const validation = appHelpers.checkPatientIntegrity(res.data);
          if (res.data.clinic_id != null) {
            setClinicId(res.data.clinic_id);
          }
          if (validation !== true) {
            setOverlay(
              <DataUpdater
                showMainModal={(
                  title,
                  content,
                  actions,
                  customStyles,
                  contentCustomClassName
                ) =>
                  props.showModal(
                    title,
                    content,
                    actions,
                    customStyles,
                    contentCustomClassName
                  )
                }
                hideMainLoading={props.hideMainLoading}
                onSave={() => {
                  setOverlay(null);
                }}
                onCancel={() => {
                  setOverlay(null);
                }}
                patientData={{
                  userId: patientId,
                  userType: USER_TYPE_PATIENT,
                  userData: res.data,
                  title: validation.title,
                  message: validation.message,
                  fieldValue: validation.field,
                  fieldName: validation.fieldName,
                  type: validation.type,
                  fieldTitle: validation.fieldTitle,
                  fieldPlaceholder: validation.fieldPlaceholder,
                }}
              />
            );
          }
        })
        .catch((err: any) => {
          helpers.processError(err);
        });

      api
        .getPatientMedicalInsurances(patientId)
        .then((res: any) => {
          if (res.data.length > 0) {
            let data = res.data.map((item: any) => {
              return {
                patients_medical_insurances_id: item.id,
                medical_insurance_id: item.medical_insurance_id,
                medical_insurance_name: item.medical_insurance.name,
                medical_insurance_plan: item.medical_insurance_plan,
                medical_insurance_number: item.medical_insurance_number,
                medical_insurance_selected: item.medical_insurance_selected,
                tieneCobertura: item.medical_insurance.tieneCobertura
                  ? item.medical_insurance.tieneCobertura
                  : 0,
                idFinanciador: item.medical_insurance.idFinanciador
                  ? item.medical_insurance.idFinanciador
                  : 0,
              };
            });

            data = data.sort((a: any, b: any) => {
              if (a.medical_insurance_id === 1) return 1;
              if (b.medical_insurance_id === 1) return -1;
              return 0;
            });

            if (
              permissions &&
              !permissions.some(
                (perm: any) => perm.tag === INSURANCE_PERMISSION
              )
            ) {
              setInsurancePermissionFlag(false);
              let indexSelected = -1;
              let idInsuranceSelected = -1;
              data = data.map((insurance: any, index: number) => {
                insurance.medical_insurance_selected =
                  insurance.medical_insurance_name === "Sin cobertura" ? 1 : 0;
                indexSelected = index;
                idInsuranceSelected = insurance.medical_insurance_id;
                return insurance;
              });
              if (indexSelected != -1 && idInsuranceSelected != -1) {
                onMedicalInsuranceSelect(indexSelected, idInsuranceSelected);
              }
            } else {
              setInsurancePermissionFlag(true);
            }

            setMedicalInsurances(data);
            setenableGeneric(data.some((item: any) => item.medical_insurance_selected == 1 && !item.medical_insurance_name.toLowerCase().includes("tarmed") && !item.medical_insurance_name.toLowerCase().includes('mpn')))

            if (
              data.some(
                (insurance: any) => insurance.medical_insurance_selected === 1
              )
            ) {
              setMedicalInsuranceSelected(true);
            }
          } else {
            const newItem = [
              {
                id: 0,
                medical_insurance_id: 1,
                medical_insurance_plan: "",
                medical_insurance_number: "",
                medical_insurance_name: "Sin cobertura",
                medical_insurance_selected: 1,
                tieneCobertura: 0,
                idFinanciador: 0,
              },
            ];
            setMedicalInsurances(newItem);
          }
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    }
  };

  useEffect(() => {
    validatePatient();
  }, [patientId]);

  useEffect(() => {
    if (patientId && prescriptionProfiles && clinicId) {
      setProfileByPatientId(patientId, clinicId, prescriptionProfiles);
    }
  }, [patientId, prescriptionProfiles, clinicId]);

  useEffect(() => {
    if (prescriptionProfileId) {
      let result = 0;
      prescriptionProfiles.map((profile) => {
        if (Number(profile.id) === Number(prescriptionProfileId)) {
          result = prescriptionProfileId;
        }
      });
      if (!result) {
        showModalInfo(
          locales_es.infoModal.title,
          "El perfil de receta seleccionado que está intentando usar ya no existe. Revise sus perfiles de receta y elija uno nuevo."
        );
        setPrescriptionProfileId(null);
        removeFromStorage(AsyncStorageEnum.PRESCRIPTION_PROFILE_ID);
      }
    }
  }, [prescriptionProfileId]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(EVENT_CLEAR_PATIENT, () => {
      setPatientId(null);
      setUserData(null);
    });

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_VALIDATE_PATIENT,
      () => {
        validatePatient();
      }
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  useEffect(() => {
    scrollToTop();
  }, [overlay]);

  const loadMedicine = (selectedItem: any) => {
    const _medicines: any = [...medicines];
    const existMEdicine = medicines.find(
      ({ qubito_reg_no, drug, commercial_name }) =>
        qubito_reg_no
          ? qubito_reg_no === selectedItem.qubito_reg_no
          : drug === selectedItem.drug &&
            commercial_name === selectedItem.commercial_name
    );
    if (existMEdicine) {
      showModalInfo(
        locales_es.global.errorTitle,
        locales_es.medicine_duplicate
      );
      return;
    }

    if (localStorage.getItem("tieneCobertura") === "1") {
      selectedItem.no_replacement = true;
    }

    _medicines.push(selectedItem);

    setMedicines(_medicines);
  };

  const loadPermissions = () => {
    return new Promise((resolve) => {
      api
        .getPermissionsMe()
        .then((res: any) => {
          setPermissions(res);
          resolve(res);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
    });
  };

  const confirmPermissions = () => {
    history.goBack();
  };

  useEffect(() => {
    loadPermissions().then((res: any) => {
      if (res && res.some((perm: any) => perm.tag === MEDICINES_PERMISSION)) {
        if (params && params.patientId) {
          loadPatientData(params.patientId);
        }

        loadPrescriptionProfiles();

        if (params && params.selectedItem) {
          loadMedicine(params.selectedItem);
          return;
        }

        coachMarksService
          .checkIfHasToShow(Routes.AddPrescription)
          .then((res: any) => {
            if (res !== true) {
              setCoachmark(true);
            }
            coachMarksService.setShownScreen(Routes.AddPrescription);
          })
          .catch((err: any) => {
            console.log(err);
          });

        return;
      }
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.modalPermission.title,
        message: locales_es.modalPermission.description,
        actions: [
          { text: "Aceptar", onPress: confirmPermissions, white: true },
        ],
      });
    });
  }, [history]);

  useEffect(() => {
    const unblock = history.block((newLocation: any, action: any) => {
      if (action === "POP") {
        if (
          (medicines.length || patientId) &&
          !alreadySent &&
          !goBackAlreadyDismissed
        ) {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.confirmBackAlert.title,
            message: locales_es.confirmBackAlert.subtitle,
            actions: [
              {
                text: locales_es.confirmBackAlert.cancel,
                onPress: () => console.log("Cancel Pressed"),
                white: true,
              },
              {
                text: locales_es.confirmBackAlert.accept,
                onPress: () => {
                  // history.push(newLocation);
                  goBackAlreadyDismissed = true;
                  history.goBack();
                },
                white: true,
                colors: [Colors.Tomato, Colors.Red],
              },
            ],
          });
          return false;
        }
        return true;
      } else {
        // If a "POP" action event occurs,
        // Send user back to the originating location
        // history.go(1);
        return true;
      }
    });

    return () => {
      unblock();
    };
  }, [medicines, patientId, alreadySent]);

  const setMedicineQuantity = (index: any, num: any) => {
    const _medicines = [...medicines];
    _medicines[index].quantity = num;
    setMedicines(_medicines);
  };

  const setMedicineDiagnostic = (index: number, diagnostic: any) => {
    const _medicines = [...medicines];
    _medicines[index].diagnostic = diagnostic;
    setMedicines(_medicines);
  };

  const removeMedicine = (index: any) => {
    const _medicines = [...medicines];
    _medicines.splice(index, 1);
    setMedicines(_medicines);
  };

  const removeAllMedicine = () => {
    setMedicines([]);
  };

  const onSwitchPressOnlyGeneric = (index: any) => {
    const _medicines = [...medicines];
    _medicines[index].is_generic = !_medicines[index].is_generic;
    if (_medicines[index].no_replacement && _medicines[index].is_generic) {
      _medicines[index].no_replacement = !_medicines[index].no_replacement;
    }
    setMedicines(_medicines);
  };

  const onSwitchPressNoReplacement = (index: any) => {
    const _medicines = [...medicines];
    _medicines[index].no_replacement = !_medicines[index].no_replacement;

    if (_medicines[index].no_replacement && _medicines[index].is_generic) {
      _medicines[index].is_generic = !_medicines[index].is_generic;
    }
    setMedicines(_medicines);
  };

  const onSwitchPressNeedsDuplicate = (index: any) => {
    const _medicines = [...medicines];

    if (
      _medicines[index].is_duplicated &&
      _medicines[index].need_duplicate_reason
    ) {
      onSwitchPressIconNeedsDuplicate(index);
    }

    _medicines[index].is_duplicated = !_medicines[index].is_duplicated;

    setMedicines(_medicines);
  };

  const onSwitchPressIconNeedsDuplicate = (index: any) => {
    const _medicines = [...medicines];
    const message = _medicines[index].need_duplicate_reason;

    if (message) {
      EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
        title: locales_es.infoModal.title,
        message:
          "Este ítem debe imprimirse duplicado según nuestros registros, por la siguiente razón: " +
          message,
      });
    }
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  const correctPatientNoInsurance = () => {
    setPrescriptionError(false);
    history.push(`/${Routes.PatientMedicalInsuranceHub}`, {
      patientId,
      prescriptionData,
      historyBackSteps: 2,
    });
  };

  const sendWithNoInsurance = () => {
    setPrescriptionError(false);
    confirmSend(PRESCRIPTION_MODE_NO_MEDICAL_INSURANCE);
  };

  const onSend = async () => {

    // validador para cuit obligatopiro por pais
    const { validateCuit, requestCuit, requestIdTributary } = await validateCuitByCountry();

    let isOsde = false
    for (let i = 0; i < medicalInsurances.length; i++) {
      if(medicalInsurances[i].medical_insurance_selected == 1 && medicalInsurances[i].medical_insurance_id == 206){
        isOsde = true;
      }
    }


    if(isOsde && requestIdTributary){
      if(requestCuit)// cuit obligatorio
      {
        showModalWithInputCuit(
          locales_es.requiredCuitModal.title,
          sendCuit,
          null,
          '00-0000000-0',
          locales_es.requiredCuitModal.subtitle,
          '50%'
        );
      }else{// de lo contrario el cancelar tambien enviara el cuit
        showModalWithInputCuit(
          locales_es.requiredCuitModal.title,
          sendCuit,
          sendData,
          '00-0000000-0',
          locales_es.requiredCuitModal.subtitle,
          '50%'
        );
      }

    }else{
      sendData();
    }


  };

  const sendData = () => {
    const errors = validateEmptyFields({
      prescriptionProfileId,
      patientId,
      // diagnostic,
      // indications,
    });
    if (validateEmptyArrays(medicines) <= 0) {
      showModalInfo(
        locales_es.infoModal.title,
        locales_es.needToAddAMedicineAtLeast
      );
      return;
    }
    if (!medicalInsuranceSelected && insurancePermissionFlag) {
      showModalInfo(
        locales_es.infoModal.title,
        locales_es.needToSelectInsurance
      );
      return;
    }
    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
      // return;
    } else {
      localAuthService
        .getConfig()
        .then(() => {
          confirmSend();
          /*
          // WOOPI TODO qué vamos a hacer con el localAuth en Web?
          if (res === true) {
            LocalAuth.authenticate({
              reason: locales_es.unlockYourPhoneToCreatePrescription,
              fallbackToPasscode: true, // fallback to passcode on cancel
              suppressEnterPassword: true, // disallow Enter Password fallback
            })
              .then(success => {
                confirmSend();
              })
              .catch(error => {
                Alert.alert(
                  locales_es.errorModal.title,
                  locales_es.invalidAccess,
                );
              });
          } else {
            confirmSend();
          }*/
        })
        .catch(() => {
          confirmSend();
        });
    }
  };



  const sendCuit = (cuit: string = '') => {

    const _userData:any = localStorage.getItem('user_data');
    let user:any = JSON.parse(_userData).user;
    if(user){
      api
        .updateCuit({
          id:user.id,
          idTributario: cuit,
        })
        .then((res: any) => {
          sendData();
        })
        .catch((err: any) => {
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(err)
          );
        });
    }else{
      showModalInfo(
        locales_es.errorModal.title,
        helpers.getErrorMsg(locales_es.errorFormatCuit)
      );
    }
  };




  const postAPIPrescription = (date: any, mode: string) => {
    return new Promise((resolve, reject) => {
      const objData = {
        prescription_profile_id: prescriptionProfileId,
        patient_id: patientId,
        // date: dateTimeService.parseDateToAPIString(new Date()),
        date: dateTimeService.parseDateToAPIString(new Date(date)),
        diagnostic,
        diagnostic_cod: diagnosticCod,
        indications,
        comments,
        medicines: medicines,
        extended_treatment: extendedTreatment,
        is_hiv: hiv,
        mode: mode || "normal",
      };

      // alert(JSON.stringify(objData));
      api
        .postPrescription(objData)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          // props.navigation.setOptions({headerShown: true});
          setLoading(false);
          setLoadingText("");
          setProgress(0);
          setLoading(false);
          setLoadingText("");
          //aqui se muestra el error de credenciales invalidas para OSDE y Luis Pasteur
          if (
            err.response?.status === 406 &&
            err.response?.data?.error_code ===
              PRESCRIPTION_ERROR_INVALID_CREDENTIAL
          ) {
            setPrescriptionError(err.response?.data?.message || true);
            setPrescriptionData(objData);
          } else if (
            err.response?.status === 406 &&
            err.response?.data?.error_code ===
              PRESCRIPTION_ERROR_INVALID_TAX_ID
          ) {
            showModalWithInputCuit(
              locales_es.requiredCuitModal.title,
              sendCuit,
              null,
              '00-0000000-0',
              locales_es.requiredCuitModal.subtitle,
              '50%'
            );
          } else {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
          }
          reject(err);
        });
    });
  };

  const confirmSend = async (mode: any) => {
    const hasShowProgress = dates.length === 1;
    // alert(JSON.stringify(dates));
    hasShowProgress ? setLoading(true) : setProgress(0.001);
    const plural = dates.length > 1;
    // props.navigation.setOptions({headerShown: false});
    setLoadingText(
      plural
        ? locales_es.creatingPrescriptions + "..."
        : locales_es.creatingPrescription + "..."
    );
    const documents = [];
    for (const date of dates) {
      const result = await postAPIPrescription(date, mode);

      documents.push({
        pdfSource: { uri: result.data.full_file_url },
        prescriptionId: result.data.id,
        medicines: result.data.medicines,
      });
      showProgress(dates.length, documents.length);
      if (documents.length === dates.length) {
        setProgress(0);
        setAlreadySent(true);
        setLoading(false);
        setLoadingText("");
        setCongrats(true);
        setLoadingText(
          plural
            ? locales_es.createdPrescriptions
            : locales_es.createdPrescription
        );
        // props.navigation.setOptions({headerShown: false});
        EventRegister.emit(EVENT_RELOAD_PRESCRIPTIONS);

        setTimeout(() => {
          if (prescriptionRequestId) {
            api
              .putPrescriptionRequestStatus(prescriptionRequestId, {
                medical_prescription_id: result.data.id,
                status: PRESCRIPTION_REQUEST_STATUS_ACCEPTED,
              })
              .then((res: any) => {
                showModalInfo(locales_es.successModal.title, res.message);
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.errorModal.title,
                  helpers.getErrorMsg(err)
                );
              });
          }

          /*setOverlay(
            <DocumentVisor showMainModal={(title, content, actions, customStyles, contentCustomClassName) => props.showModal(title, content, actions, customStyles, contentCustomClassName)}
                           hideMainLoading={props.hideMainLoading}
                           documents={documents}
                           selectionType={ConsultsType.Prescription}
                           emails={result.data.patient && result.data.patient.email
                             ? [{value: result.data.patient.email}]
                             : null}
            />
          )*/

          if (result.secondPrescription) {
            documents.push({
              pdfSource: { uri: result.secondPrescription.full_file_url },
              prescriptionId: result.secondPrescription.id,
              medicines: result.data.medicines,
            });
          }

          history.push(`${Routes.DocumentVisor}`, {
            // screenTitle: locales_es.createdPrescription,
            // pdfSource: {uri: result.data.full_file_url},
            // prescriptionId: result.data.id,
            documents,
            selectionType: ConsultsType.Prescription,
            emails:
              result.data.patient && result.data.patient.email
                ? [{ value: result.data.patient.email }]
                : null,
            overlay: true,
            patientId,
            initialSource
          });
          // props.navigation.setOptions({headerShown: true});
          setCongrats(false);
        }, 6000);
      }
    }
    return;
  };

  const onPressActivate = () => {
    showModalInfo(
      locales_es.successModal.title,
      locales_es.prescriptionsActivatedNow
    );
    localAuthService.setConfig(true);
    setCoachmark(false);
  };

  const onPressLater = () => {
    showModalInfo(
      locales_es.infoModal.title,
      locales_es.prescriptionsActivatedLater
    );
    setCoachmark(false);
  };

  const setDateArray = (date: any, index: number) => {
    const _dates = [...dates];
    _dates[index] = new Date(date);
    setDates(_dates);
  };

  const addNewDate = () => {
    const _dates = [...dates];
    const newDate = new Date(_dates[_dates.length - 1]);
    newDate.setMonth(newDate.getMonth() + 1);
    _dates.push(new Date(newDate.toISOString()));
    setDates(_dates);
  };

  const removeDate = () => {
    const _dates = [...dates];
    _dates.pop();
    setDates(_dates);
  };

  const onPrescriptionProfileSelect = (profileId: any) => {
    setPrescriptionProfile(profileId);
  };

  const handleDateChange = () => (value: any) => {
    // setSelectedDate(value);
    /// const parsedDate = dateTimeService.parseDateToAPIString(value);
    // setBirthDate(parsedDate);
  };

  const onPressSearchResult = (item: any, index: any) => {
    if (item && index == -1) {
      setDiagnostic(`${item.coddiagnostico} - ${item.descdiagnostico}`);
      setDiagnosticCod(item.coddiagnostico);
    } else if (item && index != -1) {
      const _medicines = [...medicines];
      _medicines[
        index
      ].diagnostic = `${item.coddiagnostico} - ${item.descdiagnostico}`;
      _medicines[index].diagnostic_cod = item.coddiagnostico;
      setMedicines(_medicines);
    }
  };

  const onSetObservation = (index: any, text: any) => {
    const _medicines = [...medicines];
    _medicines[index].observations = text;
    setMedicines(_medicines);
  };

  const onMedicalInsuranceSelect = (index: any, idSelected: number = -1) => {
    const medicalInsuranceSelected = medicalInsurances.map((item, ind) => {
      if (
        ind === index ||
        (idSelected !== -1 && idSelected == item.medical_insurance_id)
      ) {
        item.medical_insurance_selected = 1;
      } else {
        item.medical_insurance_selected = 0;
      }
      return item;
    });

    setenableGeneric(medicalInsuranceSelected.some((item: any) => item.medical_insurance_selected == 1 && !item.medical_insurance_name.toLowerCase().includes("tarmed") && !item.medical_insurance_name.toLowerCase().includes('mpn')))

    if (idSelected == -1) {
      setMedicalInsurances(medicalInsuranceSelected);
      setMedicalInsuranceSelected(true);
    } else {
      setMedicalInsuranceIdSelected(null);
    }

    const objectData = {
      medicals_insurances: medicalInsuranceSelected,
    };

    api
      .updateMedicalsInsurancesSelected(patientId, objectData)
      .then((res: any) => {
        {
          console.log(res);
        }
      })
      .catch((error: any) => {
        {
          console.log(error);
        }
      });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return {
    handleDateChange,
    onPrescriptionProfileSelect,
    removeDate,
    addNewDate,
    setDateArray,
    onPressLater,
    onPressActivate,
    confirmSend,
    postAPIPrescription,
    onSend,
    onBlur,
    onSwitchPressIconNeedsDuplicate,
    onSwitchPressNeedsDuplicate,
    onSwitchPressNoReplacement,
    onSwitchPressOnlyGeneric,
    setMedicineQuantity,
    removeMedicine,
    removeAllMedicine,
    setData,
    params,
    goBackAlreadyDismissed,
    prescriptionId,
    setPrescriptionId,
    prescriptionRequestId,
    setPrescriptionRequestId,
    patientId,
    setPatientId,
    errors,
    setErrors,
    prescriptionError,
    progress,
    setProgress,
    showProgress,
    overlay,
    setOverlay,
    congrats,
    setCongrats,
    prescriptionProfiles,
    setPrescriptionProfiles,
    loading,
    setLoading,
    alreadySent,
    setAlreadySent,
    loadingText,
    setLoadingText,
    prescriptionProfileId,
    setPrescriptionProfileId,
    userData,
    setUserData,
    diagnostic,
    setDiagnostic,
    diagnosticCod,
    setDiagnosticCod,
    indications,
    setIndications,
    comments,
    setComments,
    extendedTreatment,
    setExtendedTreatment,
    hiv,
    setHiv,
    coachmark,
    setCoachmark,
    medicines,
    setMedicines,
    theme,
    initialDate,
    minDate,
    dates,
    setDates,
    medicalInsurances,
    loadMedicine,
    setMedicineDiagnostic,
    onPressSearchResult,
    setPrescriptionError,
    sendWithNoInsurance,
    correctPatientNoInsurance,
    onMedicalInsuranceSelect,
    onSetObservation,
    scrollToTop,
    medicalInsuranceIdSelected,
    permissions,
    overflowFlag,
    setOverflowFlag,
    insurancePermissionFlag,
    selectPrescriptionProfileEnabled,
    enableGeneric
  };
};

export default useAddPrescriptionPage;
