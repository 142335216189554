import React, {memo, useCallback, useRef, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageSourcePropType,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  Animated,
  Easing,
  Text as NativeText,
} from 'react-native';
import Text from '../../components/Text';
import {Colors, Constants, Routes} from '../../configs';
import Theme from '../../style/Theme';
import {ConsultsStatus, ConsultsType, width} from '../../configs/Const';
import {useHistory} from 'react-router-dom';
import {consult} from '../../type/consult';
import {useTheme} from '../../configs/Theme';
import locales_es from '../../locales/es';
import DateTimeService from '../../modules/DateTimeService';
import useModalWithKeyboard from '../../hooks/useModalWithKeyboard';
import ModalSelect from '../ModalSelect';
import {
  PRESCRIPTION_REQUEST_STATUS_CREATED,
  PRESCRIPTION_REQUEST_STATUS_ACCEPTED,
  PRESCRIPTION_REQUEST_STATUS_IGNORED,
  PRESCRIPTION_REQUEST_STATUS_REJECTED,
} from '../../models/constants';
// import Collapsible from 'react-native-collapsible';
import iconDrug from '../../images/ic_add_drug@3x.png';
import iconChatActive from '../../images/ic_chat_active@3x.png';
import iconAddFile from '../../images/ic_add_file@3x.png';
import iconArrowDown from '../../images/ic_arr_down@3x.png';
import iconTopic from '../../images/ic_topic@3x.png';
import Collapsible from "react-collapsible";
import Helpers from "../../modules/helpers";
import ClinicTag from '../ClinicTag/ClinicTag';

interface PrescriptionItemProps {
  id: number;
  user: {
    img: number;
    name: string;
    gender: string;
    age: number;
    phone: string;
  };
  status: number;
  time: consult['time'];
  type: ConsultsType;
  stylesItem: any;
}

export const getStatus = (status: ConsultsStatus) => {
  switch (status) {
    case PRESCRIPTION_REQUEST_STATUS_CREATED:
      return {
        statusName: locales_es.createdRequest,
        statusColor: Colors.Blue,
      };
    case PRESCRIPTION_REQUEST_STATUS_IGNORED:
      return {
        statusName: locales_es.ignoredRequest,
        statusColor: Colors.Tomato,
      };
    case PRESCRIPTION_REQUEST_STATUS_REJECTED:
      return {
        statusName: locales_es.rejectedRequest,
        statusColor: Colors.RedNeonFuchsia,
      };
    case PRESCRIPTION_REQUEST_STATUS_ACCEPTED:
      return {
        statusName: locales_es.acceptedRequest,
        statusColor: Colors.Jade,
      };
    case ConsultsStatus.stillInProgress:
      return {
        statusName: 'Borrador',
        statusColor: Colors.BlueCrayola,
      };
    case ConsultsStatus.accepted:
      return {
        statusName: 'Enviada',
        statusColor: Colors.TealBlue,
      };
    case ConsultsStatus.unConFirmed:
      return {
        statusName: 'Unconfirmed',
        statusColor: Colors.Orange,
      };
    case ConsultsStatus.completed:
      return {
        statusName: 'Completed',
        statusColor: Colors.Jade,
      };

    case ConsultsStatus.iDeclined:
      return {
        statusName: 'I Declined',
        statusColor: Colors.RedNeonFuchsia,
      };
    case ConsultsStatus.iCanceled:
      return {
        statusName: 'I Canceled',
        statusColor: Colors.RedNeonFuchsia,
      };

    case ConsultsStatus.patientCanceled:
      return {
        statusName: 'Patient Canceled',
        statusColor: Colors.RedNeonFuchsia,
      };
    default:
      return {
        statusName: locales_es.generated,
        statusColor: Colors.TealBlue,
      };
  }
};

const PrescriptionItem = memo((props: PrescriptionItemProps) => {
  const helpers = new Helpers();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [overlay, setOverlay] = useState(false);
  const handleOnPressItem = item => {
    item.onPressItem();
  };

  const dateTimeService = new DateTimeService();
  let typeName: string;
  let iconSource: ImageSourcePropType;
  let iconStyle: StyleSheet;
  let itemProfile: string;
  const {statusName, statusColor} = getStatus(props.status);

  switch (props.type) {
    case ConsultsType.Prescription:
      typeName = locales_es.prescription;
      iconSource = iconDrug;
      iconStyle = styles.icon;
      itemProfile = Routes.AddPrescription;
      break;
    case ConsultsType.PrescriptionRequest:
      typeName = locales_es.prescriptionRequest;
      iconSource = iconChatActive;
      iconStyle = [styles.icon, styles.iconPrescriptionRequest];
      itemProfile = Routes.AddPrescription;
      break;
    case ConsultsType.Certificate:
      typeName = locales_es.certificate;
      iconSource = iconAddFile;
      iconStyle = [styles.icon, styles.iconCertificate];
      itemProfile = Routes.AddCertificate;
      break;
  }

  const choices = [
    {
      id: 1,
      name: `Ver ${typeName || ''}`,
      onPressItem: (item: any) =>{

        let emails = props.type === ConsultsType.PrescriptionRequest ? [{value: props.medica_prescription.patient.email}] : props.type === ConsultsType.Prescription || props.type === ConsultsType.Certificate ? [{value: props.patient.email}] : null;

        onPressSeePrescription(
          props.pdfSource || {uri: props.type === ConsultsType.PrescriptionRequest  ? props.medica_prescription.full_file_url : props.full_file_url},
          props.prescriptionId || props.id,
          emails,
          Boolean(props.prescriptionId || props.id),
          0
        )}
    },
  ];

  if (props.prescription_enabled) {
    choices.push({
      id: 2,
      name: `Reutilizar ${typeName || ''}`,
      onPressItem: (item: any) =>
        onPressReusePrescription(props.medical_prescription_id || props.id, props.patient_id),
    });
  }

  const choiceProcess = [
    {
      id: 1,
      name: `Procesar solicitud`,
      onPressItem: (item: any) =>{
        onPressProcessPrescription(props.id, props.patient_id)
      }
    }
  ]

  const history = useHistory();
  
  const onPressSeePrescription = useCallback(
    (pdfSource, prescriptionId, emails, hideToast, patientId) => {
      history.push(`/${Routes.DocumentVisor}`, {
        documents: [
          {
            pdfSource,
            prescriptionId,
          },
        ],
        selectionType: props.type,
        emails,
        hideToast,
      });
    },
    [history],
  );

  const onPressReusePrescription = useCallback(
    (prescriptionId, patientId) => {
      history.push(`/${itemProfile}`, {
        prescriptionId,
        patientId,
      });
    },
    [history],
  );

  const onPressProcessPrescription = useCallback(
    (id,patientId) => {
    history.push(`/${Routes.AddPrescription}`, {
      prescriptionRequestId: id,
      patientId: patientId,
    });
  }, [history]);

  const {theme} = useTheme();

  const {
    visible: visibleMenuOption,
    open: openMenuOption,
    close: closeMenuOption,
  } = useModalWithKeyboard();

  const {
    visible: visibleMenuProcess,
    open: openMenuProcess,
    close: closeMenuProcess,
  } = useModalWithKeyboard();

  const animatedIconRotation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedIconRotation, {
      duration: 150,
      easing: Easing.linear,
      toValue: isCollapsed ? 0 : 180,
      useNativeDriver: true,
    }).start();
  }, [isCollapsed]);

  const renderMedicine = (item, index) => {
    return (
      <>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1}}>
            <View
              key={index.toString()}
              style={{
                // paddingHorizontal: 24,
                marginVertical: 10,
                zIndex: 1,
              }}>
              <Text
                blueLight
                size={15}
                lineHeight={24}
                color={Colors.BlueCrayola}
                medium>
                <NativeText
                  style={[
                    // item.is_generic ? styles.lineThrough : {},
                    item.no_replacement ? styles.underline : {},
                  ]}>
                  {`${item.quantity}x${
                    item.is_generic ? '' : ' ' + item.commercial_name
                  }`}
                </NativeText>{' '}
                ({item.drug}) {item.presentation}
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  };

  const renderMedicinesCollapsible = () => {
    return props.medicines && props.medicines.length ? (
      <>
        <TouchableWithoutFeedback
          style={styles.profileListIcon}
          onPress={() => {
            setIsCollapsed(!isCollapsed);
          }}>
          <View
            style={{
              flex: 1,
              alignItems: 'space-between',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}>
            <Text size={15} lineHeight={18} marginTop={4}>
              {locales_es.medicines}: {props.medicines.length}
            </Text>
            <Animated.View
              style={{
                transform: [
                  {
                    rotate: animatedIconRotation.interpolate({
                      inputRange: [0, 360],
                      outputRange: ['0deg', '-360deg'],
                    }),
                  },
                ],
              }}>
              <Image
                source={iconArrowDown}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: isCollapsed
                    ? Colors.MediumTurquoise
                    : Colors.Platinum,
                }}
                resizeMode="contain"
              />
            </Animated.View>
          </View>
        </TouchableWithoutFeedback>
        <Collapsible trigger open={!isCollapsed}>
          {props.medicines.map(renderMedicine)}
        </Collapsible>
      </>
    ) : (
      <Text size={15} lineHeight={18} marginTop={8}>
        Texto Libre
      </Text>
    );
  };

  const renderPractice = (item, index) => {
    return (
      <>
        <View
          key={index.toString()}
          style={{
            // paddingHorizontal: 24,
            marginVertical: 5,
            zIndex: 1,
          }}>
          {item && item.medical_practice ? (
            <Text
              blueLight
              size={15}
              lineHeight={18}
              color={Colors.BlueCrayola}
              medium>
              {`${
                item.medical_practice.code
                  ? item.medical_practice.code + ' - '
                  : ''
              }${item.medical_practice.name}`}
            </Text>
          ) : (
            <Text
              blueLight
              size={15}
              lineHeight={18}
              color={Colors.BlueCrayola}
              medium>
              {locales_es.practiceDeprecated}
            </Text>
          )}
        </View>
      </>
    );
  };

  const renderPracticesCollapsible = () => {
    return props.medical_certificates_practices &&
    props.medical_certificates_practices.length ? (
      <>
        <TouchableWithoutFeedback
          style={styles.profileListIcon}
          onPress={() => {
            setIsCollapsed(!isCollapsed);
          }}>
          <View
            style={{
              flex: 1,
              alignItems: 'space-between',
              justifyContent: 'space-between',
              flexDirection: 'row',
              marginBottom: 5,
            }}>
            <Text size={15} lineHeight={18} marginTop={8}>
              {props.medical_certificates_practices.length} Prácticas
            </Text>
            <Animated.View
              style={{
                transform: [
                  {
                    rotate: animatedIconRotation.interpolate({
                      inputRange: [0, 360],
                      outputRange: ['0deg', '-360deg'],
                    }),
                  },
                ],
              }}>
              <Image
                source={iconArrowDown}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: isCollapsed
                    ? Colors.MediumTurquoise
                    : Colors.Platinum,
                }}
                resizeMode="contain"
              />
            </Animated.View>
          </View>
        </TouchableWithoutFeedback>
        <Collapsible trigger open={!isCollapsed}>
          {props.medical_certificates_practices.map(renderPractice)}
        </Collapsible>
      </>
    ) : (
      <Text size={15} lineHeight={18} marginTop={8}>
        Texto Libre
      </Text>
    );
  };

  const responsiveWidth = () => { 
    const widthSize = window.innerWidth
    
    return widthSize
  }

  
  return (
    <TouchableOpacity
      style={[styles.container, {backgroundColor: theme.backgroundItem},{width: props.stylesItem ? props.stylesItem.width : '' }]}
      onPress={props.onPressItem === false ? openMenuProcess : props.onPressItem || openMenuOption}
      /*onPress={() =>
        onPressSeePrescription(
          {uri: props.full_file_url},
          props.id,
          props.patient && props.patient.email
            ? [{value: props.patient.email}]
            : null,
          // [{value: 'torres.sebastiand@gmail.com'}],
        )
      }*/
    >
      <View
        style={{
          width: 56,
          height: 56,
        }}>
        <Image
          source={
            props.patient
              ? {uri: props.patient.full_profile_image}
              : iconTopic
          }
          style={{
            width: 56,
            height: 56,
          }}
        />
        <View
          style={{
            width: 24,
            height: 24,
            position: 'absolute',
            bottom: -8,
            right: -8,
          }}>
          <Image style={iconStyle} source={iconSource} resizeMode="contain"/>
        </View>
      </View>

      <View style={{flex: 1, marginLeft: 24}}>
        <View style={Theme.flexRow}>
          <Text size={13} lineHeight={16} color={Colors.GrayBlue}>
            {typeName} {props.count}
          </Text>
          <Text size={13} lineHeight={16} marginLeft={9} color={statusColor}>
            {statusName}
          </Text>
        </View>
        {props.patient && (
          <Text size={15} lineHeight={18} bold marginTop={8}>
            {props.patient.name} {props.patient.lastname}
          </Text>
        )}
        <View style={{flexWrap: 'wrap'}}>
          <ClinicTag
            clinicIcon={(props.patient.clinic) ? true : false}
            iconStyle={{marginRight: 5, width: (props.patient.clinic?.name) ? 24 : 20, height: (props.patient.clinic?.name) ? 24 : 20}}
            title={props.patient.clinic?.name ?? 'Propio'}
          />
        </View>
        {renderMedicinesCollapsible()}
        {
          props.type === ConsultsType.Certificate &&
          renderPracticesCollapsible()
        }
        <Text size={13} lineHeight={16} marginTop={8}>
          {dateTimeService.reverseAPIStringDate(props.date)}
        </Text>
      </View>

      <Modal
        visible={visibleMenuOption}
        onRequestClose={closeMenuOption}
        transparent
        animationType={'fade'}>
        <ModalSelect
        style={{
          alignSelf: 'center',
          width: '50%'
        }}
          onPressItem={handleOnPressItem}
          choices={choices}
          close={closeMenuOption}
        />
      </Modal>

      <Modal
        visible={visibleMenuProcess}
        onRequestClose={closeMenuProcess}
        transparent
        animationType={'fade'}>
        <ModalSelect
        style={{
          alignSelf: 'center',
          width: '50%'
        }}
          onPressItem={handleOnPressItem}
          choices={choiceProcess}
          close={closeMenuProcess}
        />
      </Modal>
    </TouchableOpacity>
  );
});

export default PrescriptionItem;

const styles = StyleSheet.create({
  container: {
    padding: 24,
    borderRadius: 16,
    width: Constants.width - 48,
    ...Theme.shadow,
    ...Theme.flexRow,
    marginBottom: 16,
  },
  icon: {
    height: 28,
  },
  iconPrescriptionRequest: {
    tintColor: Colors.Orange,
  },
  iconCertificate: {
    // tintColor: Colors.BlueCrayola,
  },
  profileListIcon: {
    marginHorizontal: 5,
  },
  lineThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  underline: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
});
