import React, {
  useState,
  useEffect, useRef,

} from 'react';
import {
  ActivityIndicator,
  Alert,
  FlatList,
  Modal,
  StyleSheet,
  View,
  ScrollView
} from "react-native";

import {
  getBottomSpace,
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import HeaderButton from "../../components/HeaderButton";
import SearchBox from "../../components/Home/SearchBox";
import Layout from "../../components/Layout/Layout";
import Text from "../../components/Text";
import { Colors, Routes } from "../../configs";
import { SOURCE_ICON } from "../../images";
import Theme from "../../style/Theme";

import { useHistory } from "react-router-dom";
import Container from "../../components/Layout/Container";
import MedicineItem from "../../components/MedicineItem";
import ModalPresentationsSelect from "../../components/ModalPresentationsSelect";
import { useTheme } from "../../configs/Theme";
import useModalWithKeyboard from "../../hooks/useModalWithKeyboard";
import { api, helpers } from "../../import";
import locales_es from "../../locales/es";
import { VADEMECUM_TYPE_QBI2 } from "../../models/constants";
import CountryService from "../../modules/CountryService";
import SearchItemList from "../../components/SearchItemList";
import { showModalInfo, showModalWithInput } from "../../modules/ViewHelpers";
import { CommonStyles } from "../../styles/CommonStyles";
import InputApp from '../../components/InputApp';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import ButtonBorder from '../../components/Buttons/ButtonBorder';
import { useDebounce } from '../SearchPatientPrescriptions/Shared/useSearchPatientPrescription';

interface SearchMedicinesProps {}

let nativeEventKeySearch = "";
const SearchMedicines = (props: SearchMedicinesProps) => {
  const history = useHistory();
  const [errors, setErrors] = useState<any>([]);
  const [keySearch, setKeySearch] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any>(false);
  const [customPresentationModal, setCustomPresentationModal] = useState<any>(false);
  const [customPresentation, setCustomPresentation] = useState<any>("");
  const [presentationsList, setPresentationsList] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [vademecumType, setVademecumType] = useState<any>('');
  const [page, setPage] = useState<any>(1);
  const [pageToSearch, setPageToSearch] = useState<any>(1);
  const [loadingProcess, setLoadingProcess] = useState<any>(false);
  const [hasMoreResults, setHasMoreResults] = useState<any>(false);
  const [keyInSearch, setKeyInSearch] = useState('');
  let vademecumRegNoCondition =
    vademecumType && vademecumType === VADEMECUM_TYPE_QBI2;
  const contentTableRef = useRef();
  const debouncedSearchTerm = useDebounce(keySearch, 1000); 

  const countryService = new CountryService();
  countryService
    .getData()
    .then((res: any) => {
      if (res && res.vademecum_service) {
        setVademecumType(res.vademecum_service);
      }
    })
    .catch((err: any) => {
      console.log(err);
    });

  const { theme } = useTheme();

  const {
    visible: visiblePresentationsModal,
    open: openPresentationsModal,
    close: closePresentationsModal,
  } = useModalWithKeyboard();

  const onKeySearchChange = (e:any) => {
    // e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    nativeEventKeySearch = e.nativeEvent.text;
    setPageToSearch(1);
    setPage(0);
    setKeySearch(e.nativeEvent.text);
  };

  const onKeySearchClear = () => {
    // e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    nativeEventKeySearch = '';
    setKeySearch('');
    setPage(0);
    setPageToSearch(1);
  };

  useEffect(() => {
    if (debouncedSearchTerm && pageToSearch == (page + 1)) {

      if (pageToSearch == 1) {
        setSearchResults(null);
      }

      const objData:any = {
        page: pageToSearch,
        q: debouncedSearchTerm,
        reg_no: true
      };

      if (vademecumRegNoCondition) {
        objData.reg_no = true;
      }

      localStorage.setItem("tieneCobertura",'0');
      const patientSelected:any = localStorage.getItem("patientSelected");
      const patient = JSON.parse(patientSelected);
      let idFinanciador = 0;
      let tieneCobertura = 0;

      // Buscamos la lista de financiadores
      if(patient && patient.patients_medical_insurances && localStorage.getItem("medical_insurance_id_check")){
          for (let index = 0; index < patient.patients_medical_insurances.length; index++) {
            const p = patient.patients_medical_insurances[index];


            if(localStorage.getItem("medical_insurance_id_check") == p.medical_insurance_id){

              idFinanciador = p.medical_insurance.idFinanciador?p.medical_insurance.idFinanciador:0;
              tieneCobertura = p.medical_insurance.tieneCobertura?p.medical_insurance.tieneCobertura:null;

              objData['afiliadoDni'] = patient.identification;
              objData['afiliadoCredencial'] = p.medical_insurance_number;
              
              //Vademecun Especial
              if(idFinanciador > 0 && tieneCobertura == 1){
                objData['nroFinanciador'] = idFinanciador;
                localStorage.setItem("tieneCobertura",'1');
              }

              break;
            }

          }
      }

      if (debouncedSearchTerm != keyInSearch && debouncedSearchTerm != '' && debouncedSearchTerm.length === nativeEventKeySearch.length) {
        setLoadingProcess(true);
        setKeyInSearch(debouncedSearchTerm);

        api
          .searchMedicines(objData)
          .then((res: any) => {
            setSearchResults((pageToSearch > 1 && searchResults) ? searchResults.concat(res.data) : res.data);
            setPage(pageToSearch);
            setLoadingProcess(false);
            if (res.pageInfo) {
              setHasMoreResults(res.pageInfo.tieneMasResultados);
              setTimeout(() => {
                onScroll(res.pageInfo.tieneMasResultados, true, pageToSearch);
              }, 100);
            }
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.infoModal.title,
              helpers.getErrorMsg(err)
            );
            setLoadingProcess(false);
            setPageToSearch(page);
          });
      }

    }

  }, [debouncedSearchTerm, vademecumType, pageToSearch, loadingProcess]);

  const onPressItem = (item:any) => {
    const presentations = JSON.parse(JSON.stringify(item.presentations));
    if (!vademecumRegNoCondition) {
      presentations.push(
        vademecumRegNoCondition
          ? {
              name: locales_es.customPresentation,
              qubito_reg_no: null,
            }
          : locales_es.customPresentation
      );
    }
    setPresentationsList(presentations);
    setSelectedItem(item);
    openPresentationsModal();
  };

  const renderItem = ({ item }: any) => {
    return <MedicineItem item={item} onPress={() => onPressItem(item)} />;
  };

  const onPresentationPress = (presentation: any) => {
    if (customPresentationModal && !presentation && !presentation.name) {
      Alert.alert(
        locales_es.infoModal.title,
        locales_es.writeYourCustomPresentation
      );
      return;
    }
    if (
      !customPresentationModal &&
      presentation &&
      (presentation.name === locales_es.customPresentation ||
        presentation === locales_es.customPresentation)
    ) {
      setTimeout(() => {
        showModalWithInput(
          '',
          (pres: any) => {
            setCustomPresentation(pres)
            onPresentationPress(pres);
          },
          presentation ?? presentation.name,
          '',
          '50%',
          () => {
            setCustomPresentation("");
            onPresentationPress("");
          }
        );
      }, 1500);
      return;
    }
    const currentItem = JSON.parse(JSON.stringify(selectedItem));
    currentItem.qubito_reg_no = vademecumRegNoCondition
      ? presentation.qubito_reg_no
      : null;
    currentItem.presentation = vademecumRegNoCondition
      ? presentation.name
      : presentation;
    currentItem.quantity = 1;
    currentItem.observations = "";
    currentItem.diagnostic = "";
    currentItem.diagnosticCod = "";
    currentItem.is_generic = false;
    currentItem.no_replacement = false;
    currentItem.is_duplicated = currentItem.need_duplicate;

    setSelectedItem(currentItem);
    props.onSelectItem
      ? props.onSelectItem(currentItem)
      : history.push(`${Routes.AddPrescription}`, {
          selectedItem: currentItem,
        });
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item:any) => item !== inputStr));
  };

  const closeCustomPresentationModal = () => {
    setCustomPresentationModal(false);
    setCustomPresentation("");
  };

  const onScroll = (moreResults = null, onResponse = false, pageIN = null) => {
    const pageToVerif = pageIN != null ? pageIN : page;
    if (contentTableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentTableRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom && !loadingProcess && pageToVerif == pageToSearch && ((hasMoreResults && !onResponse) || (moreResults != null && moreResults && onResponse))) {
        setPageToSearch(pageToVerif + 1);
      }
    }
  };

  useEffect(() => {
    const listInnerElement = contentTableRef.current;
    if (listInnerElement && searchResults && searchResults.length) {
      listInnerElement.addEventListener("scroll", onScroll);
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [searchResults, hasMoreResults, page, loadingProcess, pageToSearch]);

  return (
    <>
      <Layout style={styles.header}>
        <HeaderButton
          icon={SOURCE_ICON.close}
          style={{
            marginTop: 26,
            marginLeft: 24,
          }}
          onPress={props.onCancel}
        />
        <SearchBox
          placeholder={locales_es.searchMedicine}
          value={keySearch}
          onChange={onKeySearchChange}
          borderColor={Colors.TealBlue}
          shadow={false}
          style={styles.searchBox}
          autoFocus={true}
          onClear={onKeySearchClear}
        />
      </Layout>
      <View style={styles.container} ref={contentTableRef} >
        <View
          style={{
            ...Theme.flexOne,
            marginBottom: 20,
            justifyContent: "flex-start",
          }}
        >
          {!keySearch ? (
            <Text size={13} lineHeight={16} bold center>
              {locales_es.searchMedicine}...
            </Text>
          ) : searchResults && searchResults.length ? (
            <SearchItemList
              data={searchResults}
              renderItem={renderItem}
              contentContainerStyle={styles.contentContainerStyle}
            />
          ) : !loadingProcess ? (
            <Text size={13} lineHeight={16} bold>
              {locales_es.searchNotFound}
            </Text>
          ) : null}
          {loadingProcess && <ActivityIndicator style={CommonStyles.activityMonitor}/>}
        </View>
        <Modal
          visible={visiblePresentationsModal}
          onRequestClose={closePresentationsModal}
          transparent
          animationType={"fade"}
        >
          <ModalPresentationsSelect
            onPressItem={onPresentationPress}
            choices={presentationsList}
            close={closePresentationsModal}
            style={{ width: "800px" }}
          />
        </Modal>
      </View>
    </>
  );
};

export default SearchMedicines;

const styles = StyleSheet.create({
  container: {
    paddingTop: 120,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace() + 12,
    ...Theme.flexOne,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  scrollView: {
    flex: 1,
  },
  header: {
    ...Theme.flexRowSpace,
    paddingRight: 24,
    position: 'fixed',
    width: '100%',
    zIndex: 100,
    position: 'absolute'
  },
  cancelButton: {
    height: 48,
    marginTop: getStatusBarHeight(),
  },
  searchBox: {
    marginLeft: 24,
    marginBottom: 12,
    flex: 1,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 8,
    ...Theme.center,
    backgroundColor: Colors.DodgerBlue,
    marginRight: 16,
  },
  contentContainerStyle: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  keyboardAwareScrollContentContainerStyle: {
    flex: 1,
    width: "100%",
    backgroundColor: "#00000054",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  keyboardAwareScrollContentContainerStyleView: {
    width: "80%",
  },
});
